var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup medium-size",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'common_PayOrder',
      ])}}}),_vm._v(" "+_vm._s(_vm.$t('common_PayOrder.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content"},[_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'common_deliveryCost',
      ])}}}),_c('PriceCardBig',{staticClass:"small big",attrs:{"value":_vm.getCurrencySign + _vm.orderPrice,"text":_vm.$t('common_deliveryCost.localization_value.value')}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"buy-label__btn d-flex align-items-center",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'common_CancelCourier',
        'common_Pay',
        'poshta_generateDocs',
      ])}}}),_c('span',{staticClass:"site-link site-link--alt buy-label__btn-i mr-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_close.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"buy-label__btn-i btn-fit-content",class:{'disabled-btn' : _vm.disableBtn},attrs:{"value":_vm.generateDocsBtn ? _vm.$t('poshta_generateDocs.localization_value.value') : _vm.$t('common_Pay.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('createLabel')}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }