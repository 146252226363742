<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_PayOrder',
        ])"></div>
        {{$t('common_PayOrder.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
       <div class="custom-row">
         <div class="custom-col"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate([
          'common_deliveryCost',
        ])"></div>
           <PriceCardBig
               class="small big"
               :value="getCurrencySign + orderPrice"
               :text="$t('common_deliveryCost.localization_value.value')"
           />
         </div>
       </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_CancelCourier',
          'common_Pay',
          'poshta_generateDocs',
        ])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{$t('common_close.localization_value.value')}}
        </span>

        <MainButton
            :value="generateDocsBtn ? $t('poshta_generateDocs.localization_value.value') : $t('common_Pay.localization_value.value')"
            class="buy-label__btn-i btn-fit-content"
            @click.native="$emit('createLabel')"
            v-bind:class="{'disabled-btn' : disableBtn}"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../commonModals/Modal.vue'
  import MainButton from '../../../UI/buttons/MainButton/MainButton.vue'
  import PriceCardBig from "../../PriceCardBig/PriceCardBig";

  export default {
    name: "PayOrderPopup",

    components: {
      Modal,
      MainButton,
      PriceCardBig,
    },

    computed: {
      getCurrencySign(){
        let ico = '$'
        switch (this.currency) {
          case 'UAH':
            ico = '₴'
            break
          case 'USD':
            ico = '$'
            break
        }
        return ico
      },
    },

    props: {
      orderPrice: String,
      disableBtn: Boolean,
      currency: {
        type: String,
        default: 'USD'
      },
      generateDocsBtn: {
        type: Boolean,
        default: false
      }
    }

  }

</script>

<style scoped>

</style>
